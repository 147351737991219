import { InfoBox } from '@perts/ui';
import { Terms } from 'components';
import {
  countSelected,
  extractClassFacilitatorsFromFormValues,
} from './helpers';
import { FormValues } from './types';

export const DisplayFacilitatorCount = ({
  values,
  terms,
}: {
  values: FormValues;
  terms: Terms;
}) => {
  const {
    owned_teams,
    managed_teams,
    owned_teams_indeterminate,
    managed_teams_indeterminate,
  } = extractClassFacilitatorsFromFormValues(values);
  const numWithOwnedTeams = countSelected(owned_teams);
  const numWithManagedTeams = countSelected(managed_teams);

  return (
    <InfoBox>
      {owned_teams_indeterminate || managed_teams_indeterminate ? (
        <>{terms.classes} have mixed settings.</>
      ) : (
        <>
          {numWithOwnedTeams} {terms.classManagers}, {numWithManagedTeams}{' '}
          {terms.classTeachers} selected.
        </>
      )}
    </InfoBox>
  );
};
