import { useContext } from 'react';
import {
  differenceWith,
  isEqual,
  sortBy,
  unionWith,
  uniq,
  uniqBy,
} from 'lodash';
import { useMutation, useQueryClient } from 'react-query';
import { message } from 'antd';

import {
  Class,
  User,
  queryKeyClassesByGroup,
  queryKeyUsersByGroup,
  selectClassFacilitators,
  useClassesQueryByParams,
  useGroupsQueryByClasses,
  useUsersQueryByParams,
  selectClassGroups,
  updateUser,
} from 'models';
import { useParams } from 'pages';
import { useCloseModal, useGetCheckedStates } from 'utils';
import getUserName from 'utils/getUserName';

import Loading from 'components/Loading';
import { ErrorMessageBox } from 'components/ErrorMessageBox';

import { ClassesEditUsersForm } from './ClassesEditUsersForm';
import { createUserClassGroupMap } from './createUserClassGroupMapping';
import { usersInviteToClassesGroups } from './usersInviteToClassesGroups';
import TermsContext from 'components/TermsContext';
import { getMessageFromErrors } from '@perts/util';
import { FormValues } from './types';
import { extractClassFacilitatorsFromFormValues } from './helpers';
import { getInitialValues } from './getInitialValues';

export const ClassesEditUsers = () => {
  const checked = useGetCheckedStates();
  const closeModal = useCloseModal();
  const terms = useContext(TermsContext);
  const queryClient = useQueryClient();
  const { groupId } = useParams();
  const queryKeyClasses = queryKeyClassesByGroup(groupId);
  const queryKeyUsers = queryKeyUsersByGroup(groupId);

  // Query for Classes of Group.
  const {
    isLoading: classesIsLoading,
    data: classes = [],
    isError: classesIsError,
    error: classesError,
  } = useClassesQueryByParams();

  // Query for Users of Group.
  const {
    isLoading: usersIsLoading,
    data: users = [],
    isError: usersIsError,
    error: usersError,
  } = useUsersQueryByParams();

  // Query for Groups associated with the Classes.
  const {
    isLoading: classGroupsIsLoading,
    isError: classGroupsIsError,
    data: classGroups = [],
    error: classGroupsError,
  } = useGroupsQueryByClasses(classes);

  // Email is shown as an alternative if the facilitator does not have a name
  const usersFilled: User[] = users.map((user) => ({
    ...user,
    name: getUserName(user),
  }));

  // Add facilitators and groups to each Class.
  const classesFilled: Class[] = classes.map((cls) => ({
    ...cls,
    groups: selectClassGroups(cls, classGroups),
    facilitators: selectClassFacilitators(cls, usersFilled),
  }));

  // Filter to classes that have been selected.
  const classesChecked: Class[] = classesFilled.filter(
    (cls) => checked[cls.uid],
  );

  // Generate an array of class lead, class, and group combinations. This will
  // be used later to determine which classes/groups to add/remove users from.
  const userClassGroupMapCurrent = createUserClassGroupMap(classesChecked);

  // Mutation: Edit facilitators from classes.
  // https://react-query.tanstack.com/guides/mutations
  const mutation = useMutation(
    async (values: FormValues) => {
      const { owned_teams, managed_teams } =
        extractClassFacilitatorsFromFormValues(values);
      const classesCheckedUids = classesChecked.map((cls) => cls.uid);

      const usersFromForm = usersFilled.map((user) => {
        // Update users owned_teams (Class Lead) and managed_teams (Teacher).
        // Important. The form only returns owned_ and managed_teams associated
        // with the checked classes. So, only remove the UIDs from the users'
        // owned_ and managed_teams that are provided by the form submit.

        const ownedTeamsChecked = Boolean(
          owned_teams.find(
            (ua) => ua.userId === user.uid && ua.checked === true,
          ),
        );

        const ownedTeamsUnchecked = Boolean(
          // Explicitly check false because null indicates indeterminate.
          owned_teams.find(
            (ua) => ua.userId === user.uid && ua.checked === false,
          ),
        );

        const managedTeamsChecked = Boolean(
          managed_teams.find(
            (ua) => ua.userId === user.uid && ua.checked === true,
          ),
        );

        const managedTeamsUnchecked = Boolean(
          // Explicitly check false because null indicates indeterminate.
          managed_teams.find(
            (ua) => ua.userId === user.uid && ua.checked === false,
          ),
        );

        const updatedUser = {
          ...user,

          owned_teams: ownedTeamsChecked
            ? [...new Set([...user.owned_teams, ...classesCheckedUids])]
            : ownedTeamsUnchecked
            ? user.owned_teams.filter(
                (clsUid) => !classesCheckedUids.includes(clsUid),
              )
            : user.owned_teams,

          managed_teams: managedTeamsChecked
            ? [...new Set([...user.managed_teams, ...classesCheckedUids])]
            : managedTeamsUnchecked
            ? user.managed_teams.filter(
                (clsUid) => !classesCheckedUids.includes(clsUid),
              )
            : user.managed_teams,
        };

        return updatedUser;
      });

      // Classes array with updated facilitators based on updated users.
      const classesCheckedFromForm = classesChecked.map((cls) => ({
        ...cls,
        facilitators: selectClassFacilitators(cls, usersFromForm),
      }));

      // Generate a new array of class lead, class, and group combinations.
      const userClassGroupMapFromForm = createUserClassGroupMap(
        classesCheckedFromForm,
      );

      // Update each user's owned_organizations. Here, we want to add (but not
      // remove) any groups that are associated with classes the user is being
      // added/invited to.
      usersFromForm.forEach((user) => {
        // This users new groups (organizations).
        const usersOrganizationIdsFromMapping = userClassGroupMapFromForm
          .filter((mapping) => mapping.userId === user.uid)
          .map((mapping) => mapping.groupId);

        // Merge the existing and new owned_organizations, remove duplicates.
        user.owned_organizations = uniq([
          ...user.owned_organizations,
          ...usersOrganizationIdsFromMapping,
        ]);
      });

      // Determine the additions/invites needed.
      const invites = differenceWith(
        userClassGroupMapFromForm,
        userClassGroupMapCurrent,
        isEqual,
      );

      // Determine the removals needed (owned_teams).
      const removes = differenceWith(
        userClassGroupMapCurrent,
        userClassGroupMapFromForm,
        isEqual,
      );
      const userIdsRemoves = uniq(removes.map((r) => r.userId));
      const usersWithRemovedOwnedTeams = usersFromForm.filter((user) =>
        userIdsRemoves.includes(user.uid),
      );

      // Determmine the users with changed managed_teams.
      const usersWithChangedManagedTeams = usersFromForm.filter((user) => {
        const currentManagedTeams = user.managed_teams;

        const previousManagedTeams = users.find(
          (u) => u.uid === user.uid,
        )?.managed_teams;

        if (!previousManagedTeams) {
          return false;
        }

        const hasChanged = !isEqual(
          sortBy(previousManagedTeams),
          sortBy(currentManagedTeams),
        );

        return hasChanged;
      });

      // Combine users with owned_teams and managed_teams updates needed.
      const usersWithUpdates = uniqBy(
        [...usersWithRemovedOwnedTeams, ...usersWithChangedManagedTeams],
        'uid',
      );

      // Snapshot the previous classes value.
      const previousClasses =
        queryClient.getQueryData<Class[]>(queryKeyClasses);

      // Snapshot the previous users value.
      const previousUsers = queryClient.getQueryData<Class[]>(queryKeyUsers);

      // Optimistically update
      if (previousClasses) {
        // Merge user updates from form into existing users.
        const usersOptimistic = unionWith(
          usersFromForm,
          usersFilled,
          (updatingTo, existing) => existing.uid === updatingTo.uid,
        );

        // Merge class updates from form into existing classes.
        const classesOptimistic = unionWith(
          classesCheckedFromForm,
          // Important. Use all classes here, not just checked classes.
          classesFilled,
          (updatingTo, existing) => existing.uid === updatingTo.uid,
        );

        queryClient.setQueryData(queryKeyUsers, usersOptimistic);
        queryClient.setQueryData(queryKeyClasses, classesOptimistic);
      }

      // Invite users to classes/groups.
      await usersInviteToClassesGroups(
        groupId,
        invites,
        usersFromForm,
        classesCheckedFromForm,
      );

      // Update users
      await Promise.all(
        usersWithUpdates.map((u) =>
          updateUser(u, { organization_id: groupId }),
        ),
      );

      // Return context for rollbacks.
      return { previousUsers, previousClasses };
    },
    {
      // Handle successful mutation.
      onSuccess: (data) => {
        message.success(
          `Successfully edited ${terms.classManagers.toLowerCase()} for ` +
            `${terms.classes.toLowerCase()}.`,
        );
      },
      // If the mutation fails,
      // use the context returned from onMutate to roll back
      onError: (err, data, context: any) => {
        queryClient.setQueryData(queryKeyUsers, context.previousUsers);
        queryClient.setQueryData(queryKeyClasses, context.previousClasses);
      },
    },
  );

  // Display loading.
  const isLoading = usersIsLoading || classesIsLoading || classGroupsIsLoading;

  if (isLoading) {
    return <Loading />;
  }

  // Display errors.
  if (usersIsError || classesIsError || classGroupsIsError) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([usersError, classesError, classGroupsError])}
      </ErrorMessageBox>
    );
  }

  // Formik onSubmit handler
  const onSubmit = mutation.mutateAsync;

  const initialValues = getInitialValues(usersFilled, classesChecked);

  return (
    <ClassesEditUsersForm
      close={closeModal}
      onSubmit={onSubmit}
      initialValues={initialValues}
      classes={classesChecked}
      facilitators={usersFilled}
    />
  );
};
