import { useFormikContext } from 'formik';
import { Checkbox } from '@perts/ui';
import {
  fieldNameClassLead,
  fieldNameClassTeacher,
  isCheckedClassTeacher,
  isIndeterminateClassTeacher,
} from './helpers';
import { FormValues } from './types';

export const CheckboxClassTeacher = ({ uid }: { uid: string }) => {
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const fieldName = fieldNameClassTeacher(uid);
  const isChecked = isCheckedClassTeacher(values, uid);
  const isIndeterminate = isIndeterminateClassTeacher(values, uid);

  return (
    <Checkbox
      name={fieldName}
      label={fieldName}
      labelSrOnly
      checked={isChecked}
      indeterminate={isIndeterminate}
      onClick={async () => {
        const newClassTeacherValue = !isChecked;
        await setFieldValue(fieldName, newClassTeacherValue);

        // Rule: Cannot be Teacher without being Lead. If Teacher is
        // assigned, then also assign user as Lead, else leave current
        // setting.
        if (newClassTeacherValue === true) {
          await setFieldValue(fieldNameClassLead(uid), true);
        }
      }}
    />
  );
};
