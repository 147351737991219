import { Class, User } from 'models';
import { FormValues } from './types';
import { fieldNameClassLead, fieldNameClassTeacher } from './helpers';

const classInUsersOwnedTeams = (user: User) => (cls: Class) =>
  user.owned_teams.includes(cls.uid);

const classInUsersManagedTeams = (user: User) => (cls: Class) =>
  user.managed_teams.includes(cls.uid);

export const getInitialValues = (facilitators: User[], classes: Class[]) => {
  const initialValues: FormValues = {};

  facilitators.forEach((fac) => {
    const isClassLeadEvery = classes.every(classInUsersOwnedTeams(fac));
    const isClassLeadSome = classes.some(classInUsersOwnedTeams(fac));

    const isClassTeacherEvery = classes.every(classInUsersManagedTeams(fac));
    const isClassTeacherSome = classes.some(classInUsersManagedTeams(fac));

    initialValues[fieldNameClassLead(fac.uid)] = isClassLeadEvery
      ? true
      : isClassLeadSome
      ? null
      : false;

    initialValues[fieldNameClassTeacher(fac.uid)] = isClassTeacherEvery
      ? true
      : isClassTeacherSome
      ? null
      : false;
  });

  return initialValues;
};
