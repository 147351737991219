// Roles and Permissions Document:
// https://docs.google.com/spreadsheets/d/1IZ8a8apWvEqY50g5MuGbRYiS33qAX2ZTg3uuNpR_8cI

import {
  GroupMember,
  User,
  Group,
  selectUserIsAdmin,
  selectUserIsGroupManager,
  selectUserIsMember,
  selectUserIsNetworkManagerOfGroup,
  useCurrentUserQuery,
} from 'models';
import { useParams } from 'pages';

// eslint-disable-next-line complexity
export const usePermissions = () => {
  const { groupId } = useParams();

  // Authenticated (Current) User

  const { data: user } = useCurrentUserQuery();

  const isCurrentUser = (u: User | GroupMember) => u.uid === user?.uid;

  // Site wide `super_admin`

  const isAdmin = !user ? false : selectUserIsAdmin(user);

  // Group Permissions

  const isGroupMember = !user ? false : selectUserIsMember(user, groupId);

  const isGroupManager = !user
    ? false
    : selectUserIsGroupManager(user, groupId);
  const isNetworkManagerOfGroup = !user
    ? false
    : selectUserIsNetworkManagerOfGroup(user, groupId);

  const mayGroupEdit = isAdmin || isGroupManager;
  const mayGroupDelete = isAdmin || isGroupManager;
  const mayGroupLeave = isGroupMember;

  const mayGroupMemberEditName = isAdmin || isGroupManager;
  const mayGroupMemberEditPin = (m?: GroupMember) =>
    isAdmin ||
    isGroupManager ||
    (m ? isGroupMember && isCurrentUser(m) : false);
  const mayGroupMemberInvite = isAdmin || isGroupMember;
  const mayGroupMemberPromote = isAdmin || isGroupManager;
  const mayGroupMemberRemove = isAdmin || isGroupManager;
  const mayGroupMemberSendInvite = isAdmin || isGroupManager;
  const mayGroupMemberViewLastActive = isAdmin || isGroupManager;

  // Note: must exclude network managers.
  const mayIndividualParticipationView = isAdmin || isGroupMember;

  const mayGroupDataView = isAdmin || isGroupManager || isNetworkManagerOfGroup;

  // Note: must exclude network managers.
  const mayGroupUserDataView = isAdmin || isGroupMember;

  const maySeeTotalClasses = (g: Group) =>
    !user ? false : isAdmin || selectUserIsGroupManager(user, g.uid);

  return {
    /**
     * Returns the currently authenticated User.
     */
    currentUser: user || {},

    /**
     * Is `true` if the currentUser is User of type `super_admin`.
     */
    isAdmin,

    /**
     * Returns `true` if User `u` is the current (authenticated) User.
     */
    isCurrentUser,

    /**
     * Is `true` if the currentUser is a Group member.
     */
    isGroupMember,

    /**
     * Is `true` if the currentUser is a Group manager.
     */
    isGroupManager,

    /**
     * Is `true` if the currentUser may edit Group name.
     */
    mayGroupEdit,

    /**
     * Is `true` if the currentUser may load datasets of type `organization`.
     * See may_org_data_view() in legacy/triton/app/permission.py
     */
    mayGroupDataView,

    /**
     * Is `true` if the currentUser may load datasets of type
     * `organization-user`. See may_org_user_data_view() in
     * legacy/triton/app/permission.py
     */
    mayGroupUserDataView,

    /**
     * Is `true` if the currentUser may delete Group.
     */
    mayGroupDelete,

    /**
     * Is `true` if the currentUser may leave Group.
     */
    mayGroupLeave,

    /**
     * Is `true` if the currentUser may edit Group Member name.
     */
    mayGroupMemberEditName,

    /**
     * Returns `true` if the currentUser may pin Group for GroupMember `m`.
     */
    mayGroupMemberEditPin,

    /**
     * Is `true` if the currentUser may invite Users to Group.
     */
    mayGroupMemberInvite,

    /**
     * Is `true` if the currentUser may promote/demote Group members/managers.
     */
    mayGroupMemberPromote,

    /**
     * Is `true` if the currentUser may remove Group members from Group.
     */
    mayGroupMemberRemove,

    /**
     * Is `true` if the currentUser may send invite to Group members.
     */
    mayGroupMemberSendInvite,

    /**
     * Is `true` if the currentUser may view last active information `u`.
     */
    mayGroupMemberViewLastActive,

    /**
     * Is `true` if the currentUser may view the "View by Participant" page.
     * Excludes network managers.
     */
    mayIndividualParticipationView,

    /**
     * Returns `true` if the currentUser may see total classes for a Group `g`.
     */
    maySeeTotalClasses,
  };
};
