import React, { SyntheticEvent } from 'react';
import styled, { css } from 'styled-components/macro';

import theme from '../theme';
import { IconHelp } from '../Icon';
import { Link } from '../Link';
import { Spacer } from '../Spacer';
import { Text } from '../Text';

export type HelpTextProps = {
  // The text that will be linked.
  children: React.ReactNode;

  // The ID of the Freshworks article to link to.
  articleId?: string;

  // Custom onClick handler. Overrides linking to Freshworks article.
  onClick?: () => void;

  /**
   * Links `to` the path provided using a Link.
   */
  to?: string;

  /**
   * `true` to have help icon appear after help text.
   */
  iconRight?: boolean;
};

export const HelpTextStyled = styled(Link)<{ reverseOrder?: boolean }>`
  display: inline-flex;
  flex-direction: row;
  align-items: center;

  cursor: pointer;

  font-size: 14px;
  text-align: left;
  text-decoration: underline;

  svg {
    min-height: 20px;
    min-width: 20px;
  }

  ${(props) =>
    props.reverseOrder &&
    css`
      flex-direction: row-reverse;
    `};

  @media print {
    display: none;
  }
`;

export const HelpText = ({
  articleId,
  children,
  onClick,
  to,
  iconRight,
}: HelpTextProps) => {
  const handleOnClick = (event: SyntheticEvent) => {
    event.preventDefault();

    if (articleId) {
      const fw = (window as any).FreshworksWidget;
      fw('open', 'article', {
        id: articleId,
      });
    }
  };

  if (articleId) {
    return (
      <HelpTextStyled
        onClick={onClick || handleOnClick}
        reverseOrder={iconRight}
      >
        <IconHelp color={theme.colors.primaryDark} />
        <Spacer />
        <Text as="a">{children}</Text>
      </HelpTextStyled>
    );
  }

  if (to) {
    return <HelpTextStyled to={to}>{children}</HelpTextStyled>;
  }

  return null;
};
