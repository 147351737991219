import styled from 'styled-components/macro';

export const ModalStyles = styled.div`
  position: fixed;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;

  form {
    overflow: hidden;

    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
`;
