import { QueryClient } from 'react-query';
import {
  Group,
  GroupMember,
  User,
  UserInvitee,
  queryKeyCurrentUser,
  queryKeyUsersByGroup,
  queryKeyUsersByNetwork,
  UserToUpdate,
  Class,
  Network,
} from '../';
import {
  get,
  invite,
  InviteOptions,
  queryPagination,
  queryByOrganization,
  queryByNetwork,
  update,
  QueryParamsUserUpdate,
} from 'services/triton/users';

// TERMS:
// - Client: Group == Server: Organization

// -----------------------------------------------------------------------------
//   Server Responses
// -----------------------------------------------------------------------------

// legacy/triton/app/api_handlers.py:Invitations.post
type ResponseUsersInvite = 'new user' | 'existing user';

// -----------------------------------------------------------------------------
//   API Wrapper/Force Type Functions
//     To delay the need to rewrite the services files in ts, some of those
//     functions need some help to get ts to understand.
// -----------------------------------------------------------------------------

// services/triton/users:invite

type ApiUsersInvite = (
  user: UserInvitee,
  invitedTo: Group | Class | Network | string,
  options?: InviteOptions,
) => Promise<ResponseUsersInvite>;

const __forcedTypeUsersInvite: ApiUsersInvite = (
  invitee,
  invitedTo,
  options,
) => {
  const result = invite(invitee, invitedTo, options);
  return result as unknown as Promise<ResponseUsersInvite>;
};

// -----------------------------------------------------------------------------
//   API Functions
// -----------------------------------------------------------------------------

// Get a user by id.

type UserGetById = (userId: string) => Promise<User>;

export const userGetById: UserGetById = get;

// Query all of the users that belong to the Group with `groupId`.

type UsersQueryByGroupId = (groupId: string) => Promise<User[]>;

// Update a user by id. Note this is somewhat redundant with updateGroupMember
// below.

type UpdateCurrentUser = (user: User) => Promise<User>;

export const updateCurrentUser: UpdateCurrentUser = (user) => update(user);

export const invalidateCurrentUser = (queryClient: QueryClient) =>
  queryClient.invalidateQueries(queryKeyCurrentUser());

export const usersQueryByGroupId: UsersQueryByGroupId = queryByOrganization;

// Invite UserInvitees to Group.

type UsersInviteToGroup = (
  invitees: UserInvitee[],
  group: Group | string,
  options?: InviteOptions,
) => Promise<ResponseUsersInvite[]>;

export const usersInviteToGroup: UsersInviteToGroup = (
  invitees,
  group,
  options,
) =>
  Promise.all(
    invitees.map((invitee) => __forcedTypeUsersInvite(invitee, group, options)),
  );

export const invalidateUsersInviteToGroup = (
  queryClient: QueryClient,
  groupId: string,
) => queryClient.invalidateQueries(queryKeyUsersByGroup(groupId));

// Invite UserInvitees to Network.

type UsersInviteToNetwork = (
  invitees: UserInvitee[],
  network: Network,
  options?: InviteOptions,
) => Promise<ResponseUsersInvite[]>;

export const usersInviteToNetwork: UsersInviteToNetwork = (
  invitees,
  network,
  options,
) =>
  Promise.all(
    invitees.map((invitee) =>
      __forcedTypeUsersInvite(invitee, network, options),
    ),
  );

export const invalidateUsersInviteToNetwork = (
  queryClient: QueryClient,
  networkId: string,
) => queryClient.invalidateQueries(queryKeyUsersByNetwork(networkId));

export const invalidateUsersRemoveFromGroup = (
  queryClient: QueryClient,
  groupId: string,
) => queryClient.invalidateQueries(queryKeyUsersByGroup(groupId));

// Update GroupMember

type UpdateGroupMember = (member: GroupMember) => Promise<GroupMember>;

export const updateGroupMember: UpdateGroupMember = (member) => update(member);

// For simplicity, we're invalidating all of the users by group even though
// only a single user is being updated. This is a candidate for future network
// use improvement.
export const invalidateUpdateGroupMember = (
  queryClient: QueryClient,
  groupId: string,
) => queryClient.invalidateQueries(queryKeyUsersByGroup(groupId));

// Update GroupMembers

type UpdateGroupMembers = (members: GroupMember[]) => Promise<GroupMember[]>;

export const updateGroupMembers: UpdateGroupMembers = (members) =>
  Promise.all(members.map((member) => update(member)));

export const invalidateUpdateGroupMembers = invalidateUpdateGroupMember;

export const invalidateUsersFromGroup = (
  queryClient: QueryClient,
  groupId: string,
) => queryClient.invalidateQueries(queryKeyUsersByGroup(groupId));

// Update User

type UpdateUser = (
  user: UserToUpdate,
  queryParams?: QueryParamsUserUpdate,
) => Promise<User>;

export const updateUser: UpdateUser = (user, queryParams) =>
  update(user, queryParams);

// Update Users

type UpdateUsers = (users: UserToUpdate[]) => Promise<User[]>;

export const updateUsers: UpdateUsers = (users) =>
  Promise.all(users.map((user) => update(user)));

// Invite UserInvitees to Class.

type UserInviteToClass = (
  invitee: UserInvitee,
  cls: Class,
  options?: InviteOptions,
) => Promise<ResponseUsersInvite>;

export const userInviteToClass: UserInviteToClass = (invitee, cls, options) =>
  __forcedTypeUsersInvite(invitee, cls, options);

// Query all of the users that belong to the Network with `networkId`.

type UsersQueryByNetworkId = (networkId: string) => Promise<User[]>;

export const usersQueryByNetworkId: UsersQueryByNetworkId = queryByNetwork;

// Query users
export const usersQueryPagination = queryPagination;
