export enum PortalType {
  GOOGLE = 'google',
  EMAIL = 'email',
  STUDENT_ID = 'student_id',
}

export enum SignInProvider {
  GOOGLE = 'google.com',
  PASSWORD = 'password',
}

export enum UserType {
  USER = 'user',
  SUPER_ADMIN = 'super_admin',
}

export enum MetricLinkType {
  READING = 'reading',
  IMAGE = 'img',
}

// Values allowed in the db field user_association.type
// See `AsscType` in legacy/triton/app/model/user_association.py
export enum UserAssociationType {
  MANAGED_ORGANIZATION = 'managed_organization',
  MANAGED_TEAM = 'managed_team',
  OWNED_NETWORK = 'owned_network',
  OWNED_ORGANIZATION = 'owned_organization',
  OWNED_TEAM = 'owned_team',
  PINNED_ORGANIZATION = 'pinned_organization',
}

// Attributes/properties of user objects that hold lists of associated IDs.
export enum UserAssociationAttribute {
  MANAGED_ORGANIZATIONS = 'managed_organizations',
  MANAGED_TEAMS = 'managed_teams',
  OWNED_NETWORKS = 'owned_networks',
  OWNED_ORGANIZATIONS = 'owned_organizations',
  OWNED_TEAMS = 'owned_teams',
  PINNED_ORGANIZATIONS = 'pinned_organizations',
}
