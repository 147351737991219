import * as Yup from 'yup';
import { Terms } from 'components';
import { FormValues, FormValuesExtracted } from './types';

const identifierClassLead = 'owned_teams';
const identifierClassTeacher = 'managed_teams';

// Note: It's intentional that this is `-owned_teams` and not `owned_teams.`
// There are challenges with getting Formik, Yup, and JS objects to all play
// nicely when doing that.
export const fieldNameClassLead = (uid: string) => `${uid}-owned_teams`;
export const fieldNameClassTeacher = (uid: string) => `${uid}-managed_teams`;

export const isCheckedClassLead = (values: FormValues, uid: string) =>
  values[fieldNameClassLead(uid)];
export const isIndeterminateClassLead = (values: FormValues, uid: string) =>
  values[fieldNameClassLead(uid)] === null;

export const isCheckedClassTeacher = (values: FormValues, uid: string) =>
  values[fieldNameClassTeacher(uid)];
export const isIndeterminateClassTeacher = (values: FormValues, uid: string) =>
  values[fieldNameClassTeacher(uid)] === null;

export const extractClassFacilitatorsFromFormValues = (values: FormValues) => {
  const owned_teams: FormValuesExtracted = [];
  const managed_teams: FormValuesExtracted = [];

  const formValuesAsArray = Object.entries(values).map(
    ([fieldName, value]) => ({
      fieldName,
      value,
    }),
  );

  formValuesAsArray.forEach((val) => {
    if (val.fieldName.endsWith(`-${identifierClassLead}`)) {
      const [facilitatorUid] = val.fieldName.split('-');
      owned_teams.push({ userId: facilitatorUid, checked: val.value });
    }

    if (val.fieldName.endsWith(`-${identifierClassTeacher}`)) {
      const [facilitatorUid] = val.fieldName.split('-');
      managed_teams.push({ userId: facilitatorUid, checked: val.value });
    }
  });

  const owned_teams_indeterminate = owned_teams.some((i) => i.checked === null);
  const managed_teams_indeterminate = managed_teams.some(
    (i) => i.checked === null,
  );

  return {
    owned_teams,
    managed_teams,
    owned_teams_indeterminate,
    managed_teams_indeterminate,
  };
};

export const countSelected = (values: FormValuesExtracted) =>
  values.reduce(
    (count, currentValue) => (currentValue.checked ? count + 1 : count),
    0,
  );

export const createValidationSchema = (terms: Terms) =>
  Yup.object().test((values: FormValues) => {
    const { owned_teams, managed_teams } =
      extractClassFacilitatorsFromFormValues(values);

    const someClassLeads = owned_teams.some((u) => u.checked);

    if (!someClassLeads) {
      return new Yup.ValidationError(
        `There must be at least 1 ${terms.classManager}.`,
        values,
        '_error',
      );
    }

    const someClassTeachers = managed_teams.some((u) => u.checked);

    if (!someClassTeachers) {
      return new Yup.ValidationError(
        `There must be at least 1 ${terms.classTeacher}.`,
        values,
        '_error',
      );
    }

    return true;
  });
