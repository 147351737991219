import { Dialog as HeadlessDialog } from '@headlessui/react';
import styled from 'styled-components/macro';
import { theme } from '../../';

// See Headless "Styling the dialog" documentation for more
// https://headlessui.com/v1/react/dialog#styling-the-dialog
export const DialogComponent = (props: any) => (
  <HeadlessDialog {...props} style={{ position: 'relative', zIndex: '500' }} />
);

export const Dialog = Object.assign(DialogComponent, {
  Panel: styled((props) => <HeadlessDialog.Panel {...props} />)`
    background: ${theme.colors.white};
    border-radius: ${theme.units.borderRadius};
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.2);

    overflow: hidden;

    min-width: 600px;
    max-width: 600px;
    max-height: 700px;

    display: flex;
    flex-direction: column;
  `,
  Title: (props: any) => <HeadlessDialog.Title {...props} />,
  Description: styled((props: any) => <HeadlessDialog.Overlay {...props} />)`
    margin-bottom: 0;
  `,
  Overlay: styled((props: any) => <HeadlessDialog.Overlay {...props} />)`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    opacity: 0.3;

    @media print {
      display: none;
    }
  `,
});
