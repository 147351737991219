import styled, { css } from 'styled-components/macro';

export const Table = styled.table``;

export const THead = styled.thead``;

export const TBody = styled.tbody`
  tr {
    padding-top: 6px;
    padding-bottom: 6px;

    &:not(:last-child) {
      border-bottom: 1px solid #ccc;
    }
  }
`;

export const TR = styled.tr``;

export const TH = styled.td`
  padding: 3px;
  text-align: center;
  white-space: nowrap;
`;

export const TD = styled.td<{ nameField?: boolean }>`
  padding-top: 6px;
  padding-bottom: 6px;

  ${(props) =>
    props.nameField
      ? css`
          width: 100%;
        `
      : css`
          text-align: center;
        `};
`;
