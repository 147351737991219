import styled from 'styled-components/macro';
import { theme } from '../../';

export const ModalFooter = styled.div`
  text-align: center;

  padding: 0.8rem 1.2rem;

  &:empty {
    padding-top: 0;
    padding-bottom: 0;
  }

  border-top: 1px solid ${theme.colors.grayLight};
`;
