import Entity from 'services/Entity';
import {
  TRITON_URL_PREFIX,
  LOCAL_STORAGE_AUTH_TOKEN,
  LOCAL_STORAGE_GOOGLE_TOKEN,
} from './config';
import {
  QueryParams,
  fetchApi,
  fetchOptionsGET,
  getAuthorization,
  getJwtToken,
  handleApiResponse,
  paginationParamsToServer,
  urlWithQueryParams,
} from './helpers';

import { Group, Class, Network } from 'models';
import { UserInvitee } from 'models/User/types';
import { getKind } from '@perts/util';

// TODO FIXME
// eslint-disable-next-line
export enum UserType {
  // eslint-disable-next-line
  user = 'user',
  // eslint-disable-next-line
  superAdmin = 'super_admin',
}

export interface UserEntity extends Entity {
  name: string;
  email: string;
  sign_in_provider: 'password' | 'google.com';
  picture?: string;
  phone_number: string;
  user_type: UserType;
  owned_teams: string[];
  owned_organizations: string[];
  receive_email: boolean;
  receive_sms: boolean;
  consent: boolean;
  recent_program_id: string;
}

export const getDefaultUser = ({
  uid = '',
  name = '',
  email = '',
  owned_teams = [],
  phone_number = '',
  receive_email = true,
  receive_sms = true,
  user_type = 'user',
} = {}) => ({
  uid,
  name,
  email,
  owned_teams,
  phone_number,
  receive_email,
  receive_sms,
  user_type,
});

export const queryByTeam = (teamId) => {
  const options = {
    method: 'GET',
    headers: {
      Authorization: getAuthorization(),
    },
  };

  const url = `${TRITON_URL_PREFIX}/api/teams/${teamId}/users`;

  return fetchApi(url, options);
};

export const queryByOrganization = (orgId) => {
  const options = {
    method: 'GET',
    headers: {
      Authorization: getAuthorization(),
    },
  };

  const url = `${TRITON_URL_PREFIX}/api/organizations/${orgId}/users`;

  return fetchApi(url, options);
};

export const queryByNetwork = (networkId) => {
  const options = fetchOptionsGET();
  const url = `${TRITON_URL_PREFIX}/api/networks/${networkId}/users`;
  return fetchApi(url, options);
};

export const get = (uid) => {
  // This endpoint plays a special role in authorization. It's one of only two
  // cases where the client is allowed to use the google jwt to authorize its
  // request. Only use the google jwt if the PERTS jwt is not present.
  // See also update();
  const pertsToken = getJwtToken(LOCAL_STORAGE_AUTH_TOKEN);
  const googleToken = getJwtToken(LOCAL_STORAGE_GOOGLE_TOKEN);

  const options = {
    method: 'GET',
    headers: {
      Authorization: getAuthorization(pertsToken || googleToken),
    },
  };

  const url = `${TRITON_URL_PREFIX}/api/users/${uid}`;

  return fetchApi(url, options);
};

export const getByTeam = (teamId, userId) => {
  const options = {
    method: 'GET',
    headers: {
      Authorization: getAuthorization(),
    },
  };

  const url = `${TRITON_URL_PREFIX}/api/teams/${teamId}/users/${userId}`;

  return fetchApi(url, options);
};

export const getByEmail = (email) => {
  const options = {
    method: 'GET',
    headers: {
      Authorization: getAuthorization(),
    },
  };

  const url = `${TRITON_URL_PREFIX}/api/accounts/${email}`;

  return fetchApi(url, options);
};

export type InviteOptions = {
  // If true, will send an invitation even if the relationship isn't new.
  // Useful for refreshing the invitation to an existing group member.
  forceSendInvitation?: boolean;
  // Provides context for team/class invitations.
  orgId?: string;
};

export const invite = async (
  invitee: UserInvitee,
  invitedTo: Group | Class | Network | string,
  options?: InviteOptions,
) => {
  const uid = typeof invitedTo === 'string' ? invitedTo : invitedTo.uid;
  const kind = getKind(uid);
  if (!['Organization', 'Team', 'Network'].includes(kind)) {
    throw new Error('Must invite the user to an org or team.');
  }
  const idParam = `${kind.toLowerCase()}_id`;
  const { email } = invitee;
  const { forceSendInvitation, orgId } = options || {};

  const tritonBody = {
    email,
    [idParam]: uid, // either team_id, organization_id or network_id
    ...(orgId ? { organization_id: orgId } : {}),
    ...(forceSendInvitation === true ? { force_send_invitation: true } : {}),
  };

  const tritonUrl = `${TRITON_URL_PREFIX}/api/invitations`;
  const tritonOptions = {
    method: 'POST',
    headers: {
      Authorization: getAuthorization(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(tritonBody),
  };

  const response = await fetch(tritonUrl, tritonOptions);
  return handleApiResponse(response);
};

export type QueryParamsUserUpdate = {
  // Required when updating a user's managed_teams for permissions check.
  organization_id?: string;
};

export const update = (
  user = { uid: '' },
  queryParams: QueryParamsUserUpdate = {},
) => {
  // This endpoint plays a special role in authorization. It's one of only two
  // cases where the client is allowed to use the google jwt to authorize its
  // request. Only use the google jwt if the PERTS jwt is not present.
  // See also get();
  const pertsToken = getJwtToken(LOCAL_STORAGE_AUTH_TOKEN);
  const googleToken = getJwtToken(LOCAL_STORAGE_GOOGLE_TOKEN);

  const options = {
    method: 'PUT',
    headers: {
      Authorization: getAuthorization(pertsToken || googleToken),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user),
  };

  const url = `${TRITON_URL_PREFIX}/api/users/${user.uid}`;
  const urlWithParams = urlWithQueryParams(url, queryParams);
  return fetchApi(urlWithParams, options);
};

export const queryPagination = (queryParams: QueryParams) => {
  const options = fetchOptionsGET();
  const url = `${TRITON_URL_PREFIX}/api/users`;
  const queryParamsToServer = paginationParamsToServer(queryParams);
  const urlWithParams = urlWithQueryParams(url, queryParamsToServer);
  return fetchApi(urlWithParams, options);
};
