import { useFormikContext } from 'formik';

import { Checkbox } from '@perts/ui';
import {
  fieldNameClassLead,
  fieldNameClassTeacher,
  isCheckedClassLead,
  isIndeterminateClassLead,
} from './helpers';
import { FormValues } from './types';

export const CheckboxClassLead = ({ uid }: { uid: string }) => {
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const fieldName = fieldNameClassLead(uid);
  const isChecked = isCheckedClassLead(values, uid);
  const isIndeterminate = isIndeterminateClassLead(values, uid);

  return (
    <Checkbox
      name={fieldName}
      label={fieldName}
      labelSrOnly
      checked={isChecked}
      indeterminate={isIndeterminate}
      onClick={async () => {
        const newClassLeadValue = !isChecked;
        await setFieldValue(fieldName, newClassLeadValue);

        // Rule: Cannot be Teacher without being Lead. If Lead is unassigned
        // then also unassign user as Teacher.
        if (newClassLeadValue === false) {
          await setFieldValue(fieldNameClassTeacher(uid), false);
        }
      }}
    />
  );
};
