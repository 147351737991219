import pluralize from 'pluralize';

export const timeAgo = (timestamp: number | string) => {
  const now = new Date();
  const time = new Date(timestamp);
  const seconds = Math.floor((now.getTime() - time.getTime()) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days < 1) {
    return 'Active in the past 24 hours';
  }

  if (days <= 14) {
    return `Active ${days} ${pluralize('day', days)} ago`;
  }

  return 'Last active more than 2 weeks ago';
};
