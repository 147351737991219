import { useFormikContext } from 'formik';
import styled from 'styled-components';

import { theme } from '../../';
import { Row } from '../Row';
import { Col } from '../Col';

const FormError = styled.div`
  padding: ${theme.units.fieldPadding};
  border-radius: ${theme.units.borderRadius};
  background: ${theme.colors.warning};
  color: ${theme.colors.white};
  text-align: center;
`;

export const FormFieldErrors = ({
  displayAllErrors = true,
}: {
  /**
   * When `true` then all form field errors are displayed. When `false`, then
   * only the first error found is displayed.
   */
  displayAllErrors?: boolean;
}) => {
  const { errors, dirty } = useFormikContext();

  if (!dirty) {
    return null;
  }

  const errorMessages = Object.entries(errors).map(
    ([fieldName, fieldError]) => ({
      fieldName,
      fieldError,
    }),
  );

  if (errorMessages.length === 0) {
    return null;
  }

  return (
    <>
      {displayAllErrors ? (
        errorMessages.map((e) => (
          <Row key={e.fieldName}>
            <Col>
              <FormError
                role="alert"
                aria-live="assertive"
                id={`${e.fieldName}-field-error`}
                data-testid={`${e.fieldName}-field-error`}
              >
                {e.fieldError}
              </FormError>
            </Col>
          </Row>
        ))
      ) : (
        <Row>
          <Col>
            <FormError
              role="alert"
              aria-live="assertive"
              id={`${errorMessages[0].fieldName}-field-error`}
              data-testid={`${errorMessages[0].fieldName}-field-error`}
            >
              {errorMessages[0]}
            </FormError>
          </Col>
        </Row>
      )}
    </>
  );
};
