import React, { useContext } from 'react';
import { Switch, Route } from 'react-router-dom';

import { useProgramGetByParams, Program } from 'models';
import { toGroup } from 'pages';

import { defaultTerms, getPhrases } from './config';
import { prependTerms } from './prependTerms';
import { pluralizeTerms } from './pluralizeTerms';

/**
 * The TermsContext is designed to wrap the entire app and provide certain
 * customized strings ("terms") based on program configuration. If the program
 * cannot be determined, the value of these terms are all empty strings.
 *
 * To access the program, the provider is wrapped in a route that matches the
 * three types of paths that use programs: /home, /teams, and /organizations.
 * From this it can use path parameters to find the program in the store,
 * determine the terms, and provide them via context.
 *
 * To use (only works in the sections of the app mentioned above, and if the
 * program is loaded into the store):
 *
 *   import React, { useContext } from 'react';
 *   import TermsContext from 'components/TermsContext';
 *
 *   const MyComponent = () => {
 *     const terms = useContext(TermsContext);
 *
 *     return <div>{terms.captain}</div>;
 *   }
 *
 * OR
 *
 *   import React from 'react';
 *   import { withTermsContext } from 'components/TermsContext';
 *
 *   class MyComponent extends React.Component {
 *     render() {
 *       const { terms } = props;
 *
 *       return <div>{terms.captain}</div>;
 *     }
 *   }
 *
 *   export withTermsContext(MyComponent);
 */

export type Terms = {
  [key: string]: any;
};

// getProgramTerms returns a key:value object containing the terms that are
// specific to the provided program.
const getProgramTerms = (program?: Program) => {
  if (!program) {
    return {};
  }

  return {
    ...(program.organization_term ? { group: program.organization_term } : {}),
    ...(program.team_term ? { class: program.team_term } : {}),
    ...(program.member_term ? { classManager: program.member_term } : {}),
    ...(program.teacher_term ? { classTeacher: program.teacher_term } : {}),
    ...(program.participant_term
      ? { participant: program.participant_term }
      : {}),
    ...(program.metric_term ? { metric: program.metric_term } : {}),
  };
};

// prepareTerms combines the default terms and program specific terms, and
// handles prepending and pluralization.
export const prepareTerms = (program?: Program): Terms => {
  const mergedWithProgramTerms = {
    ...defaultTerms,
    ...getProgramTerms(program),
  };

  const terms = {
    ...mergedWithProgramTerms,
    ...prependTerms(mergedWithProgramTerms),
    ...pluralizeTerms(mergedWithProgramTerms),
  };

  const termsAndPhrases = {
    ...terms,
    ...getPhrases(terms, program),
  };

  return termsAndPhrases;
};

const initialTerms = prepareTerms();
const TermsContext = React.createContext<Terms>(initialTerms);

export const TermsProvider = ({ children }) => {
  // Query for get Program by params.
  const { data: program } = useProgramGetByParams();

  // Prepare terms, with program specific overrides.
  const terms = prepareTerms(program);

  return (
    <TermsContext.Provider value={terms}>{children}</TermsContext.Provider>
  );
};

export const RenderWithTermsContext = ({ children }) => (
  <Switch>
    {/* Routes for which a program is known and terms are applicable. */}
    <Route
      path={[
        '/home/:programId',
        '/home/:programLabel',
        '/groups/:groupId',
        '/networks/:networkId',
        '/archive/groups/:groupId',
        '/archive/networks/:networkId',
        toGroup(),
      ]}
    >
      <TermsProvider>{children}</TermsProvider>
    </Route>

    {/*
      When the program is not known, do not render the TermsProvider component
      because it needs to query for program details in order to work.
    */}
    <Route>{children}</Route>
  </Switch>
);

export const withTermsContext = (BaseComponent) => (props) =>
  (
    <TermsContext.Consumer>
      {(terms) => <BaseComponent {...props} terms={terms} />}
    </TermsContext.Consumer>
  );

// Example usage:
// import { useTerms } from 'components/TermsContext';
// const Component = () => {
//   const terms = useTerms();
//   return <Button>Add {terms.classManager}</Button>;
// }
export const useTerms = () => useContext(TermsContext);

export default TermsContext;
