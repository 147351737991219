import { Class, User } from 'models';
import { userInviteToClass, usersInviteToGroup } from 'models/User/api';

// Send an invite out to each new user, class, AND group combination. If
// a class is joined to more than one group, then multiple invites will be
// sent for that single class.

type Invite = {
  userId: string;
  groupId: string;
  classId: string;
};

type ClassInvite = Invite & {
  user: User;
  cls: Class;
};

type GroupInvite = Invite & {
  user: User;
};

// Used when saving the ClassesEditUsers modal. Accepts an Invite object for
// each user-group-class combination being created. Multiple users may have been
// added to multiple classes, and each class may be in multiple groups.
export const usersInviteToClassesGroups = async (
  currentGroupId: string,
  invites: Invite[],
  users: User[],
  classes: Class[],
) => {
  // Invite the users to the class, in the context of the current group.
  const classInvites: ClassInvite[] = [];
  // If the class is associated with one or more _other_ groups, the invited
  // user should also be invited to those groups. But in these cases, do NOT
  // also invite them to the class, for two reasons. First, there should
  // already be a ClassInvite to handle that. Second, the current user's
  // permission in those other groups is uncertain and they might not be
  // allowed to (see bug #2554).
  const groupInvites: GroupInvite[] = [];

  for (const invite of invites) {
    const user = users.find((u) => u.uid === invite?.userId);
    if (!user) {
      throw new Error(`User object for invitee not found: ${invite.userId}`);
    }

    if (invite.groupId === currentGroupId) {
      const cls = classes.find((c) => c.uid === invite?.classId);
      if (!cls) {
        throw new Error(`Class not found: ${invite.classId}`);
      }
      classInvites.push({ ...invite, user, cls });
    } else {
      groupInvites.push({ ...invite, user });
    }
  }

  for (const invite of classInvites) {
    await userInviteToClass(
      {
        email: invite.user.email,
        name: invite.user.name,
      },
      invite.cls,
      { orgId: invite.groupId },
    );
  }

  for (const invite of groupInvites) {
    await usersInviteToGroup([invite.user], invite.groupId);
  }
};
