import { ModalHeader } from './ModalHeader';
import { ModalBody } from './ModalBody';
import { ModalFooter } from './ModalFooter';
import { ModalFooterActions } from './ModalFooterActions';
import { ModalStyles } from './ModalStyles';

import { Dialog } from './HeadlessDialog';

type ModalProps = {
  // The Modal content.
  children: React.ReactNode;

  // If true, the Modal is rendered. I'm not sure this is necessary since it
  // seems we'll be using routing to determine the modal state. But I'm leaving
  // this as a reminder to figure out how to get Transition working. TODO.
  isOpen?: boolean;

  // Callback function to close the Modal. Not providing a `close` function
  // disables both the `Escape` key and clicking outside the modal to close. In
  // this case, you'll want to make sure to provide a way to close from within
  // the Modal.
  close?: () => void;
};

const noop = () => {};

const ModalComponent = ({
  children,
  isOpen = true,
  close = noop,
}: ModalProps) => {
  return (
    <Dialog open={isOpen} onClose={() => close()}>
      <Dialog.Overlay />
      <ModalStyles>
        <Dialog.Panel>{children}</Dialog.Panel>
      </ModalStyles>
    </Dialog>
  );
};

export const ModalScrollable = Object.assign(ModalComponent, {
  Header: ModalHeader,
  Title: Dialog.Title,
  Description: Dialog.Description,
  Body: ModalBody,
  Footer: ModalFooter,
  FooterActions: ModalFooterActions,
});
