import { Formik, Form, Field } from 'formik';
import pluralize from 'pluralize';

import { helpArticles } from 'config';
import { User, Class } from 'models';

import {
  Button,
  Col,
  FormFieldErrors,
  HelpText,
  ModalScrollable,
  Row,
  ScreenReaderOnly,
  useFormError,
} from '@perts/ui';
import { useTerms } from 'components';
import { CheckboxClassLead } from './CheckboxClassLead';
import { CheckboxClassTeacher } from './CheckboxClassTeacher';
import { DisplayFacilitatorCount } from './DisplayFacilitatorCount';
import { createValidationSchema } from './helpers';
import { FormValues } from './types';
import { Table, TBody, TD, TH, THead, TR } from './ClassesEditUsersTable';

export type ClassesEditUsersFormProps = {
  close: () => void;
  onSubmit: (values: FormValues) => void;
  initialValues: FormValues;

  classes: Class[];
  facilitators: User[];
};

export const ClassesEditUsersForm = ({
  close,
  onSubmit,
  initialValues,
  classes: rawClasses = [],
  facilitators = [],
}: ClassesEditUsersFormProps) => {
  const [FormError, showFormError] = useFormError();
  const terms = useTerms();
  const classes = rawClasses;
  const validationSchema = createValidationSchema(terms);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      onSubmit={async (values) => {
        try {
          // Clear existing form error.
          showFormError(false);

          // Perform form onSubmit.
          await onSubmit(values);

          // Close modal on successful form onSubmit.
          close();
        } catch (error) {
          // Display form error.
          showFormError(true);
        }
      }}
    >
      {({ isSubmitting, isValid, values, dirty }) => (
        <ModalScrollable close={close}>
          <Form>
            <ModalScrollable.Header>
              <ModalScrollable.Title>
                Edit {terms.classManagers}
              </ModalScrollable.Title>
              <ModalScrollable.Description>
                <HelpText
                  articleId={helpArticles.leadsAreAddedToCommunities}
                  iconRight
                >
                  {terms.classManagers} are added to all{' '}
                  {terms.class.toLowerCase()}
                  &rsquo;s {terms.groups.toLowerCase()}.
                </HelpText>
              </ModalScrollable.Description>
            </ModalScrollable.Header>

            <ModalScrollable.Body>
              <Table>
                <THead>
                  <TR>
                    <TH>
                      <ScreenReaderOnly>Name</ScreenReaderOnly>
                    </TH>
                    <TH align="center">
                      {
                        /* Class Lead */
                        terms.classManager
                      }
                    </TH>
                    <TH align="center">
                      {
                        /* Teacher */
                        terms.classTeacher
                      }
                    </TH>
                  </TR>
                </THead>
                <TBody>
                  {facilitators.map((fac) => (
                    <TR key={fac.uid}>
                      <TD nameField>
                        <div style={{ fontWeight: 'bold' }}>
                          {fac.name || fac.email}
                        </div>
                        <div>{fac.email}</div>
                      </TD>
                      <TD>
                        <div style={{ display: 'inline-block' }}>
                          <CheckboxClassLead uid={fac.uid} />
                        </div>
                      </TD>
                      <TD>
                        <div style={{ display: 'inline-block' }}>
                          <CheckboxClassTeacher uid={fac.uid} />
                        </div>
                      </TD>
                    </TR>
                  ))}
                </TBody>
              </Table>
            </ModalScrollable.Body>

            <ModalScrollable.Footer>
              <FormError />
              <FormFieldErrors />
              <Field name="foobar" type="hidden" />
              <DisplayFacilitatorCount values={values} terms={terms} />
            </ModalScrollable.Footer>

            <ModalScrollable.FooterActions>
              <Row>
                <Col cols={6} colsSm={12}>
                  <Button
                    type="button"
                    color="secondary"
                    fullWidth
                    onClick={close}
                    disabled={isSubmitting}
                    fullHeight
                  >
                    Cancel
                  </Button>
                </Col>

                <Col cols={6} colsSm={12} hAlign="flex-end">
                  <Button
                    type="submit"
                    fullWidth
                    disabled={!isValid || isSubmitting || !dirty}
                    loading={isSubmitting}
                    data-testid="submit-btn"
                  >
                    Save Changes to{' '}
                    {pluralize(terms.class, classes.length, true)}
                  </Button>
                </Col>
              </Row>
            </ModalScrollable.FooterActions>
          </Form>
        </ModalScrollable>
      )}
    </Formik>
  );
};
